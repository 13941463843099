import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Fab,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Button,
} from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import DimSlider from "../dim-slider";
import UnitDisplay from "./UnitDisplay";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { ValueOf } from "type-fest";
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd, CmdSendGeneralCmdWrite } from "../../types/message";
import type { RelativePathPrefix } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"0102">;
	relativePathPrefix: RelativePathPrefix;
	showFull: boolean;
};

const CONFIG_STATUS_BIT0_OPERATIONAL = 1;
const CONFIG_STATUS_BIT1_ONLINE = 2;
const CONFIG_STATUS_BIT3_LIFT_CONTROL = 8;
const BIT0_MOTOR_DIRECTION = 1;
const BIT1_CALIBRATION_MODE = 2;

const D0102 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const configStatus = cluster?.[ClusterConstants.D0102.Attributes.ConfigStatus] ?? (CONFIG_STATUS_BIT0_OPERATIONAL | CONFIG_STATUS_BIT1_ONLINE);
	const mode = cluster?.[ClusterConstants.D0102.Attributes.Mode] ?? 0;
	const configurationControls = cluster?.[ClusterConstants.D0102.Attributes.ConfigurationControls] ?? (BIT0_MOTOR_DIRECTION | BIT1_CALIBRATION_MODE);

	const [sliderValue, setSliderValue] = useDynamicUpdateState(props.deviceType.getValue(cluster));
	const [calibrationMode, setCalibrationMode] = useDynamicUpdateState(Boolean(mode & BIT1_CALIBRATION_MODE));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const operateShutterControls = (cmdId: ValueOf<typeof ClusterConstants.D0102.CmdIds>) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: cmdId,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleChangeCommitted = (newSliderValue: number) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.D0102.CmdIds.GoToLiftPercentage,
			value: decimal2Hex(newSliderValue, 2),
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const sendCmd = (value: number) => {
		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.D0102.Attributes.Mode,
				datatype: Constants.DataType.Bitmap8Bit,
				value: decimal2Hex(value, 2),
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"0102">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleCalibrationClick = () => {
		setCalibrationMode(true);
		sendCmd(mode | BIT1_CALIBRATION_MODE);
	};

	if (props.showFull) {
		return (
			<>
				{calibrationMode ? null : (configStatus & CONFIG_STATUS_BIT3_LIFT_CONTROL) ?
					<ListItem>
						<ListItemText
							primary={t("clusters.D0102.status")}
							secondary={
								<UnitDisplay
									values={[sliderValue]}
									digits={props.deviceType.getDigits()}
									unit={props.deviceType.getUnit()}
									sameFontSize={true}
								/>
							}
						/>
						<ListItemContainer style={{ width: "calc(50% - 10px)", marginRight: "10px" }}>
							<DimSlider
								value={sliderValue}
								onChange={setSliderValue}
								onChangeCommitted={handleChangeCommitted}
							/>
						</ListItemContainer>
					</ListItem>
					:
					<ListItem>
						<ListItemText primary={t("clusters.D0102.status")} />
						<ListItemText
							primary={
								<UnitDisplay
									values={[sliderValue]}
									digits={props.deviceType.getDigits()}
									unit={props.deviceType.getUnit()}
									sameFontSize={true}
								/>
							}
						/>
					</ListItem>
				}
				<Divider />
				<ListItem>
					<ListItemText primary={t("clusters.D0102.shutterControl")} />
					<ListItemContainer>
						<ListItemIcon>
							<Fab onClick={() => (operateShutterControls(ClusterConstants.D0102.CmdIds.Open))}>
								<icons.ShutterOpen />
							</Fab>
						</ListItemIcon>
						<ListItemIcon sx={{ ml: "12px" }}>
							<Fab onClick={() => (operateShutterControls(ClusterConstants.D0102.CmdIds.Stop))}>
								<icons.Stop />
							</Fab>
						</ListItemIcon>
						<ListItemIcon sx={{ ml: "12px" }}>
							<Fab onClick={() => (operateShutterControls(ClusterConstants.D0102.CmdIds.Close))}>
								<icons.ShutterClose />
							</Fab>
						</ListItemIcon>
					</ListItemContainer>
				</ListItem>
				{Boolean(configurationControls & BIT1_CALIBRATION_MODE) &&
					<>
						<Divider />
						<ListItem>
							<ListItemText primary={t("clusters.D0100.title")} secondary={t("clusters.D0100.subTitle")} />
							<ListItemContainer>
								{calibrationMode ?
									<ListItemText primary={t("clusters.D0100.running")} /> :
									<Button sx={{ margin: "-10px" }} onClick={handleCalibrationClick}>
										{t("clusters.D0100.start")}
									</Button>}
							</ListItemContainer>
						</ListItem>
					</>
				}
				{Boolean(configurationControls & BIT0_MOTOR_DIRECTION) &&
					<>
						<Divider />
						<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Shades}`}>
							<ListItemText primary={t("clusters.D0102.advancedSettings")} />
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
					</>
				}
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

D0102.defaultProps = {
	showFull: false,
};

D0102.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		getDigits: PropTypes.func.isRequired,
		getValue: PropTypes.func.isRequired,
		getUnit: PropTypes.func.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default D0102;
